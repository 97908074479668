.quantity{
    font-weight: bold;
}

.cardInfo{
    padding: 20px;
    font-size: 1.3rem;
}

.cardMain{
    font-size: 1 rem;
    text-align: center;
    padding: 30px 5px 30px 5px;
}

.observadorC{
    font-size: 3rem;
}

.solSinProceso{
    font-size: 2rem;
}
