.ant-select-selection-selected-value{
    color: black;
}

.btn_descarga_const {
    font-size: 24px;
    background: none;
    border: none;
    color: #e149a4;
    text-decoration: underline;
}
.btn_descarga_const:hover {
    background: none !important;
    border: none !important;
    color: #e149a4 !important;
    text-decoration: underline !important;
}
.btn_descarga_const:hover svg {
    color: #e149a4;
}
.btn_descarga_const:visited {
    background: none !important;
    border: none !important;
    color: #e149a4 !important;
    text-decoration: underline !important;
}
.btn_descarga_const:focus {
    background: none !important;
    border: none !important;
    color: #e149a4 !important;
    text-decoration: underline !important;
}